<template>
    <section class="resorses-wrapper">
        <headerOfSection
            :title="$t('services.resorces.title')"
            :subTitle="$t('services.resorces.subTitle')">
            {{ $t('services.resorces.headerText') }}
        </headerOfSection>
        <div class="resorses-content">
            <ul>
                <li v-if="$i18n.locale == 'en'">
                    <a href="downloads/D&D_CREDENTIAL_2020_EN.pdf">
                        <span>{{ $t('services.resorces.resourceText') }}</span>
                        <svg width="44" height="44" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg"><path d="M41.5 41v-4H44v7H0v-7h2.5v4h39zm-18-8.5l8-8L34 27 22.5 38h-1L10 27l2.5-2.5 8 8V0h3v32.5z" fill="#000" fill-rule="nonzero"></path></svg>
                    </a>
                </li>
                <li v-if="$i18n.locale == 'ja'">
                    <a href="downloads/CREDENTIAL 2020.pdf">
                        <span>{{ $t('services.resorces.resourceText') }}</span>
                        <svg width="44" height="44" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg"><path d="M41.5 41v-4H44v7H0v-7h2.5v4h39zm-18-8.5l8-8L34 27 22.5 38h-1L10 27l2.5-2.5 8 8V0h3v32.5z" fill="#000" fill-rule="nonzero"></path></svg>
                    </a>
                </li>
            </ul>
        </div>
    </section>
</template>
<script>
import headerOfSection from './../../components/section-header'
export default {
    components: {
        headerOfSection
    }
}
</script>
<style lang="scss" scoped>
@import './../../scss/app';

.resorses-wrapper {
    position: relative;
    background-color: #191a1a;
}

.resorses-content {
    padding: 4px 15vw;

    ul {
        padding-bottom: 10vw;
        display: flex;

        li {
            display: block;
            width: 50%;
        }

        a {
            display: flex;
            padding: 26px 24px 24px;
            color: #F0F0F0;
            align-items: center;
            justify-content: space-between;
            text-decoration: none;
            font-family: $family-medium;
            @include transition(all 250ms);
            background: rgba(29, 31, 31, 1);
            @include font-size(18px);
            font-size: calc(18px + 6 * ((100vw - 1280px) / 640));
            line-height: 1.2em;

            &:hover {
                position: relative;
                background: rgba(29, 31, 31, 0);
                color: #F6BD25;
                &:after {
                    @include transition(all 250ms);
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 1px;
                    background-color: #F6BD25;
                }

                svg path {
                    fill: #939999;
                }
            }
        }

        svg {
            height: 24px;
            
            path {
                fill: #3D4040;
            }
        }
    }
}

@media (max-width: 992px) {

    .resorses-content {
        padding: 5px 15vw;

        ul {

            li {
                width: 100%;
            }

            a {
                @include font-size(18px);
            }
        }
    }
}

@media (max-width: 576px) {

    .resorses-content {
        padding: 5px 10vw;

        ul {

            li {
                width: 100%;
            }
        }
    }
}

</style>