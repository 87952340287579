<template>
    <div class="title-wrapper" :class="{ 
            'title-wrapper--white': isWhite,
            'ja': $i18n.locale == 'ja'
        }">
        <div class="title">
            <div class="bar"></div>
            <h2>{{ title }}</h2>
        </div>
        <div class="content">
            <h3 v-if="subTitle">{{ subTitle }}</h3>
            <p>
              <slot />
            </p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            default: null,
            type: String
        },
        subTitle: {
            default: null,
            type: String
        }, 
        isWhite: {
            default: false,
            type: Boolean
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../scss/app';

.title-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 10vw 15vw 5vw;
    
    div {
        width: 50%;
    }

    h2, p, h3 {
        color: #F5F5F5;
    }

    .title .bar {
        background:linear-gradient(90deg, #EFA137,#9033A5);
        border-radius: 1px;
        margin: -24px 0 24px;
        height: 4px;
        width: 44px;
    }

    p {
        font-family: $family-light;
        line-height: 1.56em;
        will-change: transform;
        color: #939999;
        @include font-size(17px);
        font-size: calc(17px + 14 * ((100vw - 1280px) / 1280));
        font-weight: 400;
            
        span {
            display: block;
        }
    }

    h2 {
        letter-spacing: 0.35em;
        margin-bottom: 0;
        text-transform: uppercase;
        white-space: nowrap;
        font-weight: normal;
        font-family: $family-medium;
        line-height: 1.48em;
        @include font-size(16px);
        font-size: calc(16px + 3 * ((100vw - 1280px) / 1280));
        font-weight: 700;
    }

    h3 {
        font-family: $family-bold;
        font-weight: 400;
        line-height: 1.2em;
        margin-bottom: 0;
        font-size: 2.2vw;
        letter-spacing: 0.015em;
        color: #F0F0F0;
        margin-bottom: 1.2em;
    }
}

.title-wrapper.ja {
    h3 {
        font-weight: 600;
        font-size: 1.5vw;
    }

    h2 {
        letter-spacing: 0;
    }
}

.title-wrapper.title-wrapper--white {
    h2 {
        color: #1d1f1f;
    }

    p {
        color: #313333;
    }

    h3 {
        color: #1D1F1F;
    }
}

@media (max-width: 992px) {
    .title-wrapper {
        flex-direction: column;
        padding-top: 64px;
        padding: 10vw 10vw 5vw;

        div {
            width: 100%;
        }

        h2 {
            @include font-size(14px);
            margin-bottom: 24px;
        }

        h3 {
            @include font-size(23px);
            line-height: 1.6em;
            margin: 24px 0;
        }

        p {
            margin: 32px 0 24px;
            @include font-size(16px);

            span {
                display: inline;
            }
        }
    }

    .title-wrapper.ja {
        h3 {
            font-size: 2.5vw;
        }
    }
}

@media (max-width: 576px) {
    .title-wrapper {
        padding-top: 64px;

        h2 {
            font-size: 4.5vw;
        }

        p {
             margin: 0 0 24px;
        }
    }

    .title-wrapper.ja {
        h3 {
            font-size: 4.5vw;
        }
    }
}
</style>