<template>
    <div :style="{ 
            'transform': `translate3d(${offsetX}px, ${offsetY}px, 0px)`,
            '-o-transform': `translate3d(${offsetX}px, ${offsetY}px, 0px)`,
            '-ms-transform': `translate3d(${offsetX}px, ${offsetY}px, 0px)`,
            '-moz-transform': `translate3d(${offsetX}px, ${offsetY}px, 0px)`,
            '-webkit-transform': `transale3d(${offsetX}px, ${offsetY}px, 0px)`
          }" :class="className">
        <slot/>
    </div>
</template>

<script>
export default {
  inject: ['parallaxContainer'],
  props: {
    isActive: {
      default: true,
      type: Boolean,
    },
    factor: {
      default: 0.25,
      type: Number,
    },
    scrollX: {
      default: false,
      type: Boolean,
    },
    scrollY: {
      default: true,
      type: Boolean,
    },
    className: String
  },
  computed: {
    offsetY() {
      if(this.scrollY && this.isActive) {
        const { height, scrollFactorY } = this.parallaxContainer;
        return scrollFactorY * height * this.factor;
      }
      return 0;
    },
    offsetX() {
      if(this.scrollX && this.isActive) {
        const { width, scrollFactorX } = this.parallaxContainer;
        return scrollFactorX * width * this.factor;
      }
      return 0;
    },
  },
};
</script>