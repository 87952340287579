<template>
    <div class="img-celebrity">
        <img class="img-layer img-celebrity-part-1" src="images/services/services-partial-images/4/SVG/background.svg" alt="background">
        <img class="img-layer img-celebrity-part-2" src="images/services/services-partial-images/4/SVG/linegear1.svg" alt="linegear1">
        <img class="img-layer img-celebrity-part-3" src="images/services/services-partial-images/4/SVG/linegear2-2.svg" alt="linegear2">
        <img class="img-layer img-celebrity-part-4" src="images/services/services-partial-images/4/SVG/linegear3.svg" alt="linegear3">
        <img class="img-layer img-celebrity-part-5" src="images/services/services-partial-images/4/SVG/peoplefront.svg" alt="peoplefront">
        <paralax-container className="image-paralax-wrapper-6">
            <paralax-item :scrollY="true" :factor="0">
                <img class="img-layer img-celebrity-part-6" src="images/services/services-partial-images/4/SVG/phonebg.svg" alt="phonebg">
            </paralax-item>
        </paralax-container>
    </div>
</template>
<script>
import paralaxContainer from './../parallax-container';
import paralaxItem from './../parallax-item'

export default {
    components: {
        "paralax-container": paralaxContainer,
        "paralax-item": paralaxItem
    }
}
</script>
<style lang="scss" scoped>
@import './../../scss/app';

.img-celebrity {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index:0;
    
    $imgDesctHeight: 18vw;

    .img-layer {
        position: absolute;
    }

    &-part-1 {
        top: -6vw;
        width: $imgDesctHeight + 15vw;
        height: $imgDesctHeight + 15vw;
        z-index: 1;
    }

    &-part-2 {
        top: 7vw;
        left: 0vw;
        width: $imgDesctHeight - 12vw;
        height: $imgDesctHeight - 12vw;
        z-index: 2;
        @include animation(rotation-clockwise 5s linear infinite);
    }

    &-part-3 {
        top: -4vw;
        left: 25vw;
        width: $imgDesctHeight - 14vw;
        height: $imgDesctHeight - 14vw;
        z-index: 3;
        @include animation(rotation-counterclock-wise 5s linear infinite);
    }
    
    &-part-4 {
        top: 8vw;
        left: 27vw;
        width: $imgDesctHeight - 12vw;
        height: $imgDesctHeight - 12vw;
        z-index: 4;
        @include animation(rotation-clockwise 5s linear infinite);
    }

    &-part-5 {
        top:11.5vw;
        width: $imgDesctHeight;
        height: $imgDesctHeight - 6.5vw;
        z-index: 6;
    }

    &-part-6.img-layer {
        position: relative;
        top: 0;
        left: 2vw;
        width: $imgDesctHeight + 5vw;
        height: $imgDesctHeight + 5vw;
        z-index: 5;
    }
}

.image-paralax-wrapper-6 {
    position: relative;
    z-index: 5;
}

@include keyframes(rotation-clockwise) {
    0% {
        @include rotate(0deg);
    }
    100% {
        @include rotate(360deg);
    }
}

@include keyframes(rotation-counterclock-wise) {
    0% {
        @include rotate(0deg);
    }
    100% {
        @include rotate(-360deg);
    }
}


@media (max-width: 992px) {
    .img-celebrity {
        $imgDesctHeight: 58vw;

        & &-part-1 {
            top: -6vw;
            width: $imgDesctHeight + 15vw;
            height: $imgDesctHeight + 15vw;
        }

        & &-part-2 {
            top: 19vw;
            left: 0vw;
            width: $imgDesctHeight - 43vw;
            height: $imgDesctHeight - 43vw;
        }

        & &-part-3 {
            top: -4vw;
            left: 54vw;
            width: $imgDesctHeight - 48vw;
            height: $imgDesctHeight - 44vw;
        }
        
        & &-part-4 {
            top: 25vw;
            left: 58vw;
            width: $imgDesctHeight - 44vw;
            height: $imgDesctHeight - 44vw;
        }

        &-part-5 {
            top:17.5vw;
            width: $imgDesctHeight - 10vw;
            height: $imgDesctHeight - 6.5vw;
        }

        &-part-6.img-layer {
            top: 5px;
            left: 4vw;
            width: $imgDesctHeight + 5vw;
            height: $imgDesctHeight - 4vw;
        }
    }
}

@media (max-width: 576px) {
    .img-celebrity {
        $imgDesctHeight: 58vw;

        & &-part-1 {
            top: -6vw;
            width: $imgDesctHeight + 15vw;
            height: $imgDesctHeight + 15vw;
        }

        & &-part-2 {
            top: 19vw;
            left: 4vw;
            width: $imgDesctHeight - 43vw;
            height: $imgDesctHeight - 43vw;
        }

        & &-part-3 {
            top: -8vw;
            left: 54vw;
            width: $imgDesctHeight - 48vw;
            height: $imgDesctHeight - 44vw;
        }
        
        & &-part-4 {
            top: 25vw;
            left: 66vw;
            width: $imgDesctHeight - 44vw;
            height: $imgDesctHeight - 44vw;
        }

        &-part-5 {
            top:17.5vw;
            width: $imgDesctHeight - 10vw;
            height: $imgDesctHeight - 6.5vw;
        }

        &-part-6.img-layer {
            top: 5px;
            left: 4vw;
            width: $imgDesctHeight + 5vw;
            height: $imgDesctHeight - 4vw;
        }
    }
}

</style>