<template>
    <section class="services-list-wrapper" :class="{ 'ja': $i18n.locale == 'ja' }">
        <headerOfSection :title="$t('services.servicesList.title')" />
        <div class="content-service-line-wrapper">
            <div class="row row-right row-awards">
                <div class="row-content">
                    <h3 v-scroll="fadeIn">{{ $t('services.servicesList.awards.title') }}</h3>
                    <p v-scroll="fadeIn">{{ $t('services.servicesList.awards.text') }}</p>
                    <div class="row-list-wrap">
                         <ul v-scroll="fadeIn">
                            <li>{{ $t('services.servicesList.awards.grammys') }}</li>
                            <li>{{ $t('services.servicesList.awards.musicAwards') }}</li>
                            <li>{{ $t('services.servicesList.awards.billboard') }}</li>
                        </ul>
                        <ul v-scroll="fadeIn">
                            <li>{{ $t('services.servicesList.awards.goldAndPlatinum') }}</li>
                            <li>{{ $t('services.servicesList.awards.spotify') }}</li>
                            <li>{{ $t('services.servicesList.awards.appleMusic') }}</li>
                        </ul>
                    </div>
                </div>
                <div class="row-icon">
                    <awardsImg/>
                </div>
            </div>
            <div class="row row-left row-branding">
                <div class="row-icon">
                    <digitalPromotionImg/>
                </div>
                <div class="row-content">
                    <h3 v-scroll="fadeIn">{{ $t('services.servicesList.branding.title') }}</h3>
                    <p v-scroll="fadeIn">{{ $t('services.servicesList.branding.text') }}</p>
                    <div class="row-list-wrap">
                         <ul v-scroll="fadeIn">
                            <li>{{ $t('services.servicesList.branding.dataResearch') }}</li>
                            <li>{{ $t('services.servicesList.branding.branding') }}</li>
                            <li>{{ $t('services.servicesList.branding.businessConsulting') }}</li>
                        </ul>
                        <ul v-scroll="fadeIn">
                            <li>{{ $t('services.servicesList.branding.market') }}</li>
                            <li>{{ $t('services.servicesList.branding.mediaSelection') }}</li>
                            <li>{{ $t('services.servicesList.branding.videoCreation') }}</li>
                        </ul>
                    </div>
                </div>
            </div>
             <div class="row row-right row-digital">
                <div class="row-content">
                    <h3 v-scroll="fadeIn">{{ $t('services.servicesList.promotion.title') }}</h3>
                    <p v-scroll="fadeIn">{{ $t('services.servicesList.promotion.text') }}</p>
                    <div class="row-list-wrap">
                         <ul v-scroll="fadeIn">
                            <li>{{ $t('services.servicesList.promotion.boost') }}</li>
                            <li>{{ $t('services.servicesList.promotion.youtube') }}</li>
                            <li>{{ $t('services.servicesList.promotion.fb') }}</li>
                        </ul>
                        <ul v-scroll="fadeIn">
                            <li>{{ $t('services.servicesList.promotion.instagram') }}</li>
                            <li>{{ $t('services.servicesList.promotion.twitter') }}</li>
                            <li>{{ $t('services.servicesList.promotion.promotion') }}</li>
                        </ul>
                    </div>
                    <div class="link-container">
                        <router-link class="action-link" to="/youtube">
                            <span>{{ $t('services.servicesList.promotion.buttonYoutubePage') }}</span>
                        </router-link>    
                    </div>
                </div>
                <div class="row-icon">
                    <colaborationWithCelebritiesImg/>
                    <div class="link-container">
                        <router-link class="action-link" to="/youtube">
                            <span>{{ $t('services.servicesList.promotion.buttonYoutubePage') }}</span>
                            <svg width="44" height="25" viewBox="0 0 44 25" xmlns="http://www.w3.org/2000/svg"><path d="M38.5 11l-8-8L33 .5 44 12v1L33 24.5 30.5 22l8-8H0v-3h38.5z" fill="#fff" fill-rule="nonzero"></path></svg>
                        </router-link>    
                    </div>
                </div>
            </div>
            <div class="row row-left row-colaboration">
                <div class="row-icon">
                    <brandingImg/>
                </div>
                <div class="row-content">
                    <h3 v-scroll="fadeIn">{{ $t('services.servicesList.marketing.title') }}</h3>
                    <p v-scroll="fadeIn">{{ $t('services.servicesList.marketing.text') }}</p>
                    <div class="row-list-wrap">
                         <ul v-scroll="fadeIn">
                            <li>{{ $t('services.servicesList.marketing.matching') }}</li>
                            <li>{{ $t('services.servicesList.marketing.development') }}</li>
                            <li>{{ $t('services.servicesList.marketing.nurturing') }}</li>
                        </ul>
                        <ul v-scroll="fadeIn">
                            <li>{{ $t('services.servicesList.marketing.features') }}</li>
                            <li>{{ $t('services.servicesList.marketing.remixes') }}</li>
                            <li>{{ $t('services.servicesList.marketing.parties') }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import headerOfSection from './../../components/section-header';
import awardsImg from './../../components/images/service-award-img';
import brandingImg from './../../components/images/service-branding-img';
import digitalPromotionImg from './../../components/images/service-d-promotion';
import colaborationWithCelebritiesImg from './../../components/images/service-celebriti-img'; 

export default {
    components: {
        headerOfSection,
        awardsImg,
        brandingImg,
        digitalPromotionImg,
        colaborationWithCelebritiesImg
    },
    methods: {
        fadeIn(evt, el, windowTop) {     
            
            if(el.classList.contains('active'))
                return false;
            
            const isActive = this.getCoords(el).top <= windowTop + window.innerHeight && this.getCoords(el).top + el.offsetHeight >= windowTop

            if(!el.classList.contains('active') && isActive) {
                el.classList.add("active");
            }
        },
        getCoords(elem) { 
            const box = elem.getBoundingClientRect();

            const body = document.body;
            const docEl = document.documentElement;

            const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
            const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

            const clientTop = docEl.clientTop || body.clientTop || 0;
            const clientLeft = docEl.clientLeft || body.clientLeft || 0;

            const top  = box.top +  scrollTop - clientTop;
            const left = box.left + scrollLeft - clientLeft;

            return { top: Math.round(top), left: Math.round(left) };
        }
    }
}
</script>
<style lang="scss" scoped>

@import './../../scss/app';



.services-list-wrapper {
    position: relative;
    background-color: rgb(29, 31, 31);
}

.content-service-line-wrapper {
    padding: 5vw 15vw;

    .row {
        display: flex;
        padding-top: 8vw; 
        padding-bottom: 8vw;

        h3 {
            color: #F5F5F5;
            width: 100%;
            font-family: $family-medium;
            font-weight: 400;
            @include font-size(21px);
            font-size: calc(21px + 4 * ((100vw - 1280px) / 640));
            line-height: calc(21px + 4 * ((100vw - 1280px) / 640));
        }

        p {
            color: #F0F0F0;
            line-height: 1.56em;
            margin: 1.5vw 0 0;
            position: relative;
            @include font-size(18px);
            font-family: $family-light;
        }

        img {
            position: absolute;
            width: 100%;
        }

        li {
            display: block;
            font-family: $family-regular;
            @include font-size(15px);
            font-size: calc(15px + 3 * ((100vw - 1160px) / 760));
            color:#939999;
            line-height: 1.4em;
            margin: 1em 0;
        }

        .para-awards {
            position: absolute;
            top:-80%;
            left: 0;
            right: 0;
            bottom: 0;
            div {
                position: absolute;
                top:0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }
}
.ja {
    .row-content {
        margin-top: -5vw;
        h3 {
            @include font-size(25px);
            line-height: 1em;
            padding-bottom: 7px;
        }
    }

    .row-icon {
        margin-top: 5vw;
    }
}

.row-icon {
    position: relative;
}

.row-content, .row-icon {
    width: 50%;
}

.row-right {
    .row-content {
        padding-right: 4vw;
    }

    .row-icon {
        padding-left: 4vw;
    }
}

.row-left {
    .row-content {
        padding-left: 4vw;
    }

    .row-icon {
        padding-right: 4vw;
    }
}

.row-list-wrap {
    display: flex;
    margin-top: 24px;

    ul {
        width: 50%;
        font-family: $family-light;
        color: #F0F0F0;
        @include font-size(15px);

        li {
            line-height: 1.4em;
            margin: 0.5em 0;
        }
    }
}

.row {

    p, ul, h3 {
        @include opacity(0);
        @include transition(opacity 600ms ease-in);
        @include transition-delay(200ms);

        &.active {
            @include opacity(1);
        }
    }

}

.row-branding {
    svg {
        position: absolute;
        bottom: -2%;
        width: 77%;
        left: 10%;
    }

    img {
        position: absolute;
        bottom: -15%;
    }
}

.row-digital {


    .link-container {
        top: 40px;
        position: relative;
        display: flex;
        height: 100px;
        align-items: center;
        justify-content: center;
    }

    .row-icon {
        top: -100px;
    }

    .action-link {
        height:  55px;

        span {
            font-size: 1.3vw;
            font-weight: 600;
        }

        svg {
            width: 1.5vw;
        }
    }

    img {
        position: absolute;
        bottom: 0;
        z-index: 2;
        top:-10px;
    }

    svg {
        z-index: 1;
        position: absolute;
        top: -4.9%;
        left: 36.6%;
        height: auto;
        width: 17.8vw;

        path {
            @include animation(infinity-circle 3s);
            @include animation-iteration-count(infinite);
        }
    }
}

.para-digital {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    div {
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.row-colaboration {
    img {
        position: absolute;
        bottom: 0;
        z-index: 2;
    }

    .bg-planets {
        width:100%;
        top: -30%;
    }
}

.para-colaboration {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    div {
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    
    img {
        top: -29%;
        left: -40%;
    }  
}

.action-link {
    position: relative;
    color: rgba(0, 0, 0, 1);
    @include font-size(24px);
    line-height: 28px;
    cursor: pointer;
    z-index: 0;
    padding: 13px 20px 13px;
    background-color: #fff;

    &::after {
      content: '';
      position: absolute;
      top:0;
      bottom:0;
      left: 0;
      right: 0;
      background: #fff;
      z-index: 1;
      background-color: #4923D8;
      @include opacity(0);
      @include transition(all 500ms);
    }

    svg {
      position: relative;
      width: 25px;
      left: auto;
      top: -1.5px;
      margin-left: 12px;
      display: inline-block;
      z-index: 2;
      @include transition(all 500ms);

      path {
        fill: #000;
      }
    }

    &:hover {
      color: rgba(255, 255, 255, 1);
      &:after {
        @include opacity(1);
      }

      svg {
        margin-left: 32px;
        path {
          fill: #fff;
        }
      }
    }

    span {
        position: relative;
        display: inline-block;
        z-index: 2;
        @include font-size(24px);
        line-height: 24px;
        font-family: $family-medium;
    }
  }

  .row-content .action-link{
      display: none;
  }

@include keyframes(infinity-circle) {
    0% {
        stroke-dashoffset: 3000px;
    }

    50% {
        stroke-dashoffset: 0px;
    }

    100% {
        stroke-dashoffset: -3000px;
    }
}

@media (max-width: 992px) {

    .content-service-line-wrapper {
        .row {
            h5 {
                 @include font-size(21px);
            }
        }
    }
    
    .row-content, .row-icon {
        width: 100%;
    }
    
    .row-right {
        flex-direction: column-reverse;

        .row-icon, .row-content {
            padding: 0;
        }
    }

    .row-left {
        flex-direction: column;

        .row-icon, .row-content {
            padding: 0;
        }    
    }

    .row-icon {
        height: 80vw;
        padding-left: 0;
    }

    .content-service-line-wrapper .row .para-awards {
        top: -40%;
    }

    .row-branding {
        img {
            bottom: 5%;
        }
        svg {
            bottom: 12%;
            width: 75%;
            left: 13%;
        }
    }

    .row-digital {
        .row-icon {
            top: 0;

            .action-link {
                display: none;
            }
        }

        .row-content {
            height:  45vh;

            .link-container {
                top: 0;
            }
        }

        .row-content .action-link {
            display: block;
        }

        .link-container {
            top: 60px;
        }

        .action-link {
            span {
                font-size: 3vw;
            }

            svg {
                top: -3px;
                left: 0;
                width: 3.5vw;
            }
        }

        img {
            top:0;
        }

        svg {
            top: 20%;
            left: 25.6%;
            width: 32.8vw;
        }
    }

    .row-colaboration {
        img {
            top:0;
        }

        .bg-planets {
            top:0;
        }
    }
}

@media (max-width: 576px) {

    .services-list-wrapper {
        padding: 10vw 0;
    }

    .content-service-line-wrapper {
        padding: 5vw 10vw;

        .row p {
            @include font-size(16px);
        }
    }

    .row-digital svg {
        top: 22%;
        left: 28.6%;
    }
    

    .row-digital {
        .row-icon {
            top: 0;
        }

        .row-content {
            height: 65vh;
        }

        //top: 60px;

        .action-link {
            span {
                font-size: 4.5vw;
            }

            svg {
                top: -2px;
                left: 0;
                width: 4.5vw;
            }
        }
    }
}
</style>