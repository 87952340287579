<template>
    <div :class="className">
        <slot/>
    </div>
</template>
<script>
export default {
    provide() {
        return {
            parallaxContainer: this.data,
        };
    },
    props: {
        className: String
    },
    data() {
        return {
            data: {
                width: 0,
                scrollFactorX: 0,
                height: 0,
                scrollFactorY: 0,
            }
        };
    },
    mounted() {
        this.calcParallax();
    },
    created() {
        window.addEventListener('resize', this.eventHandler);
        window.addEventListener('scroll', this.eventHandler);
    },
    destroyed() {
        window.removeEventListener('resize', this.eventHandler);
        window.removeEventListener('scroll', this.eventHandler);
    },
    methods: {
        eventHandler() {
            return requestAnimationFrame(this.calcParallax)
        },
        calcParallax() {
            const containerRect = this.$el.getBoundingClientRect();

            this.data.height = containerRect.height;
            this.data.width = containerRect.width;

            const viewportOffsetTop = containerRect.top;
            const viewportOffsetBottom = window.innerHeight - viewportOffsetTop;

            this.data.scrollFactorY = viewportOffsetBottom / (window.innerHeight + this.data.height);
            
            this.data.scrollFactorX = viewportOffsetBottom / (window.innerHeight + this.data.width);
        }
    }
}
</script>