<template>
    <div class="img-d-promotion">
        <img class="img-layer img-d-promotion-part-1" src="images/services/services-partial-images/3/SVG/background.svg" alt="background">
        <paralax-container className="img-d-promotion-part-2-wrapper">
            <paralax-item :scrollY="true" :factor="0">
                <img class="img-layer img-d-promotion-part-2" src="images/services/services-partial-images/3/SVG/heartbg.svg" alt="heartbg">
            </paralax-item>
        </paralax-container>
        <svg class="img-layer img-d-promotion-part-3" :class="{ active: isArrowActive }" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1752.25" height="500.12" viewBox="0 0 1752.25 500.12">
            <defs>
                <linearGradient id="d-promo-gradient" y1="261.29" x2="1754.25" y2="261.29" gradientUnits="userSpaceOnUse">
                    <stop offset="0%" stop-color="#d64472"/>
                    <stop offset="5%" stop-color="#d64472"/>
                    <stop offset="10%" stop-color="#d64472"/>
                    <stop offset="15%" stop-color="#d64472"/>
                    <stop offset="20%" stop-color="#d64472"/>
                    
                    <stop offset="25%" stop-color="#DB5457"/>
                    <stop offset="30%" stop-color="#DB5457"/>
                    
                    <stop offset="35%" stop-color="#DF614A"/>
                    <stop offset="40%" stop-color="#DF614A"/>
                    
                    <stop offset="45%" stop-color="#E16B43"/>
                    <stop offset="50%" stop-color="#E16B43"/>

                    <stop offset="55%" stop-color="#C56E36"/>
                    <stop offset="60%" stop-color="#C56E36"/>
                    
                    <stop offset="65%" stop-color="#E8883A"/>
                    <stop offset="70%" stop-color="#E8883A"/>

                    <stop offset="75%" stop-color="#efa137"/>
                    <stop offset="80%" stop-color="#efa137"/>
                    <stop offset="85%" stop-color="#efa137"/>
                    <stop offset="90%" stop-color="#efa137"/>
                    <stop offset="95%" stop-color="#efa137"/>
                    <stop offset="100%" stop-color="#efa137"/> 
                </linearGradient>
                <linearGradient id="d-promo-gradient2" y1="23.13" x2="1754.25" y2="23.13" xlink:href="#d-promo-gradient"/>
            </defs>
            <g>
                <polygon fill="url(#d-promo-gradient)" points="4.19 500.12 0 497.39 183.59 215.75 399.29 391.41 669.54 25.81 671.57 27.48 1107.23 385.13 1369.61 126.25 1529.59 295.09 1732.42 22.46 1736.44 25.44 1530.05 302.84 1369.49 133.4 1107.54 391.85 1105.8 390.42 670.43 33.01 400.19 398.6 184.74 223.14 4.19 500.12"/>
                <polygon fill="url(#d-promo-gradient2)" points="1740.9 46.27 1730.74 28.9 1711.19 24.16 1752.25 0 1740.9 46.27"/>
            </g>
        </svg>
        <img class="img-layer img-d-promotion-part-4" src="images/services/services-partial-images/3/SVG/peoplefront.svg" alt="peoplefront">
    </div>
</template>
<script>
import paralaxContainer from './../parallax-container';
import paralaxItem from './../parallax-item'

export default {
    data() {
        return {
            isArrowActive: true,
            duration: 5000
        }
    },
    components: {
        "paralax-container": paralaxContainer,
        "paralax-item": paralaxItem
    },
    mounted() {

        setInterval(() => {
            this.isArrowActive = false;
            setTimeout(() => this.isArrowActive = true, 1500);
        }, this.duration);
    }
}
</script>
<style lang="scss" scoped>
@import './../../scss/app';

.img-d-promotion {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    $imgDesctHeight: 18vw;

    .img-layer {
        position: absolute;
        top: 0;
        display: block;
        width: 100%;
    }

    & &-part-1 {
        top: -7vw;
        height: $imgDesctHeight + 15vw;
        width: $imgDesctHeight + 15vw;
        z-index: 1;
    }

    & &-part-2 {
        position: relative;
        top: -5vw;
        height: $imgDesctHeight + 8vw;
        width: $imgDesctHeight + 8vw;
        z-index: 3;
    }

    & &-part-4 {
        top: -4vw;
        left: 5vw;
        height: $imgDesctHeight + 13vw;
        width: $imgDesctHeight  + 5vw;
        z-index: 3;
    }
    
    & &-part-3 {
        top: -18vw;
        height: $imgDesctHeight + 15vw;
        width: $imgDesctHeight + 10vw;
        z-index: 2;

        stop {
            stop-color: transparent;
        }

        $duration: 1.2s;
        @include transition(all $duration ease-in);
        $itemsCount: 22;

        @for $i from 1 through $itemsCount {
            stop:nth-child( #{$itemsCount}n + #{$i} ) {
                @include transition-delay($duration / $itemsCount * $i);
                stop-color: transparent;
            }
        }

        &.active {
        
            @for $i from 1 through 5 {
                 stop:nth-child( #{$itemsCount}n + #{$i} ) {
                     @include transition-delay($duration / $itemsCount * $i);
                     stop-color:#d64472;
                 }
            }
            
            @for $i from 5 through 7 {
                 stop:nth-child( #{$itemsCount}n + #{$i} ) {
                     @include transition-delay($duration / $itemsCount * $i);
                     stop-color:#DB5457;
                 }
            }     

            @for $i from 7 through 9 {
                 stop:nth-child( #{$itemsCount}n + #{$i} ) {
                     @include transition-delay($duration / $itemsCount * $i);
                     stop-color:#DF614A;
                 }
            }      

            @for $i from 9 through 11 {
                 stop:nth-child( #{$itemsCount}n + #{$i} ) {
                     @include transition-delay($duration / $itemsCount * $i);
                     stop-color:#E16B43;
                 }
            } 

            @for $i from 11 through 13 {
                 stop:nth-child( #{$itemsCount}n + #{$i} ) {
                     @include transition-delay($duration / $itemsCount * $i);
                     stop-color:#C56E36;
                 }
            }

            @for $i from 13 through 15 {
                 stop:nth-child( #{$itemsCount}n + #{$i} ) {
                     @include transition-delay($duration / $itemsCount * $i);
                     stop-color:#E8883A;
                 }
            }

            @for $i from 15 through 21 {
                 stop:nth-child( #{$itemsCount}n + #{$i} ) {
                     @include transition-delay($duration / $itemsCount * $i);
                     stop-color:#E8883A;
                 }
            }
        }
    }
}

.img-d-promotion-part-2-wrapper {
    z-index: 3;
}

@media (max-width: 992px) {
    .img-d-promotion {
        $imgDesctHeight: 58vw;

        & &-part-1 {
            top: -5vw;
            left: -6vw;
            height: $imgDesctHeight + 22vw;
            width: $imgDesctHeight + 22vw;
        }

        & &-part-2 {
            top: 1vw;
            left: -1vw;
            height: $imgDesctHeight + 4vw;
            width: $imgDesctHeight + 4vw;
        }

        & &-part-3 {
            top: -28vw;
            height: $imgDesctHeight + 15vw;
            width: $imgDesctHeight + 10vw;
        }

        & &-part-4 {
            top: 8vw;
            left: 10vw;
            height: $imgDesctHeight + 5vw;
            width: $imgDesctHeight  - 3vw;
        }
    }
}

@media (max-width: 576px) {
    .img-d-promotion {
        left:2vw;
        $imgDesctHeight: 58vw;

        & &-part-1 {
            top: -5vw;
            left: -6vw;
            height: $imgDesctHeight + 22vw;
            width: $imgDesctHeight + 22vw;
        }

        & &-part-2 {
            top: 1vw;
            left: -4vw;
            height: $imgDesctHeight + 4vw;
            width: $imgDesctHeight + 4vw;
        }

        & &-part-3 {
            top: -28vw;
            left: 2vw;
            height: $imgDesctHeight + 15vw;
            width: $imgDesctHeight + 10vw;
        }

        & &-part-4 {
            top: 8vw;
            left: 10vw;
            height: $imgDesctHeight + 5vw;
            width: $imgDesctHeight  - 3vw;
        }
    }
}
</style>