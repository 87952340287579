<template>
    <section class="adw-wrapper" :class="{'ja': $i18n.locale == 'ja'}">
        <headerOfSection
            :title="$t('services.adwantage.title')"
            :subTitle="$t('services.adwantage.subTitle')"
            :isWhite="true"
        />
        <div class="adw-content-wrapper">
            <div class="adw-side-pic-wrapper">
                <div class="shape-wrapper">
                    <div class="title">{{ $t('services.adwantage.shapeText') }}</div>
                    <div class="shape"></div>
                    <div class="shape"></div>
                    <div class="shape"></div>
                    <div class="shape"></div>
                    <div class="shape"></div>
                </div>
            </div>
            <div class="adw-controls-wrapper">
                <div class="adw-item">
                    <h5 v-scroll="fadeIn">
                        <span class="order-mark">1</span>
                        {{ $t('services.adwantage.cinsumerActivators.title') }}
                    </h5>
                    <p v-scroll="fadeIn">{{ $t('services.adwantage.cinsumerActivators.text') }}</p>
                </div>
                <div class="adw-item">
                    <h5 v-scroll="fadeIn">
                        <span class="order-mark">2</span>
                        {{ $t('services.adwantage.extensiveNetwork.title') }}
                    </h5>
                    <p v-scroll="fadeIn">{{ $t('services.adwantage.extensiveNetwork.text') }}</p>
                </div>
                <div class="adw-item">
                    <h5 v-scroll="fadeIn">
                        <span class="order-mark">3</span>
                        {{ $t('services.adwantage.directNegotiation.title') }}
                    </h5>
                    <p v-scroll="fadeIn">{{ $t('services.adwantage.directNegotiation.text') }}</p>
                </div>
                <div class="adw-item">
                    <h5 v-scroll="fadeIn">
                        <span class="order-mark">4</span>
                        {{ $t('services.adwantage.profiTeam.title') }}
                    </h5>
                    <p v-scroll="fadeIn">{{ $t('services.adwantage.profiTeam.text') }}</p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import headerOfSection from './../../components/section-header';
export default {
    components:{
        headerOfSection
    },
    methods: {
        fadeIn(evt, el, windowTop) {         

            if(el.classList.contains('active'))
                return false;

            const isActive = this.getCoords(el).top <= windowTop + window.innerHeight && this.getCoords(el).top + el.offsetHeight >= windowTop

            if(!el.classList.contains('active') && isActive) {
                el.classList.add("active");
            }
        },
        getCoords(elem) { 
            const box = elem.getBoundingClientRect();

            const body = document.body;
            const docEl = document.documentElement;

            const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
            const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

            const clientTop = docEl.clientTop || body.clientTop || 0;
            const clientLeft = docEl.clientLeft || body.clientLeft || 0;

            const top  = box.top +  scrollTop - clientTop;
            const left = box.left + scrollLeft - clientLeft;

            return { top: Math.round(top), left: Math.round(left) };
        }
    }
}
</script>
<style lang="scss" scoped>
@import './../../scss/app';

.adw-wrapper {
    position: relative;
    background-color: #f5f5f5;
}

.adw-content-wrapper {
    display: flex;
    justify-content: space-around;
    padding: 10vw;
    background: rgb(240, 240, 240);
}

.shape-wrapper {
    position: relative;
    width: 14vw;
    height: 14vw;
    min-height: 160px;
    min-width: 160px;

    .title {
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $family-bold;
        @include font-size(17px);
        font-weight: 700;
    }
    
    $loopDuration: 20s;
    $delay: 250ms;

    .shape {
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom: 0;
        @include b-radius(30%);
        will-change: auto;
        @include rotate(45deg);
        @include animation(shape-rotation $loopDuration);
        @include animation-iteration-count(infinite);
        font-family: $family-bold;
        line-height: 1.2em;
        color: #313333;
        @include font-size(17px);
        font-size: calc(17px + 5 * ((100vw - 1280px) / 1280));
    }

    .shape:nth-child(1) {
        border: 2px solid #F6BD25;
    }

    .shape:nth-child(2) {
        border: solid 2px #E78549;
        @include animation-delay($delay);
    }

    .shape:nth-child(3) {
        border: solid 2px #D64472;
        @include animation-delay($delay * 2);
    }

    .shape:nth-child(4) {
        border: solid 2px #9033A5;
        @include animation-delay($delay * 3);
    }
    
    .shape:nth-child(5) {
        border: solid 2px #4923D8;
        @include animation-delay($delay * 4);
    }
}

@include keyframes(shape-rotation) {
    0% {
        @include rotate(45deg);
    }
    100% {
        @include rotate(760deg);
    }
}

.adw-controls-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 70%;

    .adw-item {
        width:50%;
        padding:0 5% 10%;

        h5 {
            @include font-size(16px);
            font-size: calc(16px + 5 * ((100vw - 1280px) / 1280));
            line-height: calc(16px + 5 * ((100vw - 1280px) / 1280));
            color: #1D1F1F;
            letter-spacing: 0.1em;
            font-family: $family-medium;
            text-transform: uppercase;
            margin-bottom: 2.5em;
            padding: 2px 0px 0 2px;
            font-weight: 700;
            white-space: nowrap;

            span {
                position: relative;
                top: -2px;
                padding: 2px 0px 0 2px;
                display:inline-block;
                width: 24px;
                height: 24px;
                line-height: 24px;
                color: #F5F5f5;
                text-align: center;
                font-family: $family-light;
                @include font-size(11px);
                @include b-radius(1px);
            }
        }

        &:nth-child(1) h5 span {
            background: #E78549;
        }

        &:nth-child(2) h5 span {
            background: #D64472;
        }

        &:nth-child(3) h5 span {
            background: #9033A5;
        }

        &:nth-child(4) h5 span {
            background: #4923D8;
        }

        p {
             font-family: $family-light;
             color:#626666;
             line-height: 1.56em;
             font-size: calc(17px + 7 * ((100vw - 1280px) / 640));
        }
    }
}

.adw-item {
    p, h5 {
        @include opacity(0);
        @include transition(all 600ms);
        @include transition-delay(200ms);

        &.active {
            @include opacity(1);
        }
    }
}

.ja .adw-item {
    p {
        font-size: calc(15px + 7 * ((100vw - 1280px) / 640));
    }

    h5 {
        letter-spacing: 0;
        margin-bottom: 1.3em;
    }
}

@media (max-width: 992px) {

    .adw-content-wrapper {
        flex-direction: column;
    }

    .adw-side-pic-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .adw-controls-wrapper {
        width: 100%;
        padding-top: 10vh;

        .adw-item {
            padding-right: 0; 
            width: 45%;

            p {
                @include font-size(17px);
            }
        }
    }
}

@media (max-width: 576px) {

    .adw-side-pic-wrapper{
        padding-top: 10vh;
    }

    .adw-controls-wrapper {
        width: 100%;
        padding-top: 10vh;

        .adw-item {
            padding-top: 5vh; 
            padding-right: 0; 
            width: 100%;

            p {
                @include font-size(17px);
            }

            h5 {
                margin-bottom: 1.5em; 
                @include font-size(16px);
            }
        }
    }
        
}

</style>