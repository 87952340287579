<template>
  <div class="wrrapper">
    <cookies-popup />
    <navigation :isWhite="isWhiteNav"/>
    <div :style="'filter: blur('+ blurVal +'px);-webkit-filter: blur('+ blurVal +'px)'" v-if="isHeroBg" class="image-bg gradient-overlay" style="background-image: url(/images/services/christin-hume-mfB1B1s4sMc-unsplash-min.jpg);"></div>
    
    <div class="content-page-wrapper">
      <headerSection id="services-section-header"/>
      <listOfActionsSection id="services-list"/>
      <adwantageSection id="services-adwantage"/>
      <resorsesSection id="services-resourses"/>
      <footerSection/>
    </div>
  </div>
</template>

<script>
import navigation from './../components/navigation'
import headerSection from './../sections/services/header'
import listOfActionsSection from './../sections/services/services-list'
import adwantageSection from './../sections/services/adwantage'
import resorsesSection from './../sections/services/resourses'
import footerSection from './../sections/footer'
import cookiesPopup from './../components/cookies-popup'

  export default {
    mounted() {
      this.navElement = document.querySelector(".nav");

      if(this.isMobile() || this.isTablet()) {
        this.isWhiteNav = true;
      }
    },
    metaInfo: {
      title: 'D&D Promotion - Services'
    },
    components: {
      navigation, 
      headerSection, 
      listOfActionsSection,
      adwantageSection, 
      resorsesSection, 
      footerSection,
      cookiesPopup
    },
    data() {
      return {
        sectionsSettings: [
          {
            domId: "services-section-header",
            isNavWhite: true,
            isHeroBgShown: true,
            isBlureHeaderBg: true
          },
          {
            domId: "services-list",
            isNavWhite: true,
            isHeroBgShown: false,
            isBlureHeaderBg: false
          },
          {
            domId: "services-adwantage",
            isNavWhite: false,
            isHeroBgShown: false,
            isBlureHeaderBg: false
          },
          {
            domId: "services-resourses",
            isNavWhite: true,
            isHeroBgShown: false,
            isBlureHeaderBg: false
          }
        ],
        isHeroBg: true,

        isWhiteNav: true,
        navElement: null,
        blurVal: 0,

        mobileWidth: 793,
        tabletWidth: 992
      }
    },
    created() {
      window.addEventListener('scroll', this.servicesScrollOverHandler);
    },
    destroyed() {
      window.removeEventListener('scroll', this.servicesScrollOverHandler);
    },
    methods: {
      servicesScrollOverHandler() {
        const windowTop = this.getWindowTop();
        
        for(let i = 0; i < this.sectionsSettings.length; i++) {

          const section = this.sectionsSettings[i];
          const el = document.getElementById(section.domId);

          if(el.offsetTop <= windowTop
              && (el.offsetTop + el.offsetHeight) >= windowTop) {

              this.isHeroBg = section.isHeroBgShown;
              this.isWhiteNav = section.isNavWhite;

              if(section.isBlureHeaderBg) {
                this.blurVal = windowTop / 50;
              }
          }
        }

        if(this.isMobile() || this.isTablet()) {
           this.isWhiteNav = true;
        }
      },
      isMobile() {
        return this.getWindowWidth() <= this.mobileWidth;
      },
      isTablet() {
        return this.getWindowWidth() >= this.mobileWidth && this.getWindowWidth() <= this.tabletWidth;
      },
      getWindowWidth() {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      },
      getScreenRate() {
        return this.isMobile() || this.isTablet() ? 2 : 1.1;
      },
      getWindowTop() {
        return window.pageXOffset === undefined ? document.documentElement.scrollTop : window.pageYOffset;
      }
    }
  }
</script>
<style lang="scss" scoped>
.gradient-overlay {
    &::after {
    content: '';
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #4C3ED9;
    background: -moz-linear-gradient(60deg, #4C3ED9, #8C36A8, transparent 70%); 
    background: -webkit-linear-gradient(60deg, #4C3ED9, #8C36A8, transparent 70%); 
    background: linear-gradient(60deg, #4C3ED9, #8C36A8, transparent 70%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4C3ED9', endColorstr='#8C36A8',GradientType=0 );
  }
}
</style>