<template>
    <div class="img-awards">
        <img class="img-layer img-awards-part-1" src="images/services/services-partial-images/1/SVG/background.svg" alt="background">
        <svg class="img-layer img-awards-part-2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1792.43" height="804.35" viewBox="0 0 1792.43 804.35">
            <defs>
                <linearGradient id="a" x1="1587.08" y1="230.1" x2="1711.03" y2="230.1" gradientTransform="translate(-16.24 382.21) rotate(-8.96)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#d64472"/>
                    <stop offset="1" stop-color="#efa137"/>
                </linearGradient>
                <linearGradient id="b" x1="1645.81" y1="230.1" x2="1790.15" y2="230.1" xlink:href="#a"/>
                <linearGradient id="c" x1="1529.61" y1="230.1" x2="1634.2" y2="230.1" xlink:href="#a"/>
                <linearGradient id="d" x1="77.77" y1="412.32" x2="201.72" y2="412.32" gradientTransform="translate(19.48 29.8) rotate(2.72)" xlink:href="#a"/>
                <linearGradient id="e" x1="-1.35" y1="412.32" x2="142.99" y2="412.32" gradientTransform="translate(19.48 29.8) rotate(2.72)" xlink:href="#a"/>
                <linearGradient id="f" x1="154.6" y1="412.32" x2="259.18" y2="412.32" gradientTransform="translate(19.48 29.8) rotate(2.72)" xlink:href="#a"/>
            </defs>
            <g>
                <g class="lines">
                    <path class="a" :class="{ 'active': line2active }" fill="url(#a)" d="M1641.94,670.42l-4.25-2.63a475.21,475.21,0,0,0-96.45-611.67l3.24-3.81a479.66,479.66,0,0,1,97.46,618.11Z"/>
                    <path class="b" :class="{ 'active': line3active }" fill="url(#b)" d="M1706.69,704.47l-4.19-2.72a529.34,529.34,0,0,0-110-697.87L1595.61,0a534.32,534.32,0,0,1,111.08,704.47Z"/>
                    <path class="c" :class="{ 'active': line1active }" fill="url(#c)" d="M1578.72,637.8l-4.32-2.52A423.4,423.4,0,0,0,1491,106.53l3.34-3.72a427.89,427.89,0,0,1,84.36,535Z"/>
                </g>
                <g class="lines">
                    <path class="d" :class="{ 'active': line2active }" fill="url(#d)" d="M180.58,763.47a479.66,479.66,0,0,1,29.71-625l3.63,3.44a475.22,475.22,0,0,0-29.4,618.52Z"/>
                    <path class="e" :class="{ 'active': line3active }" fill="url(#e)" d="M119.91,804.35A534.36,534.36,0,0,1,153.77,92l3.56,3.51a529.34,529.34,0,0,0-33.54,705.7Z"/>
                    <path class="f" :class="{ 'active': line1active }" fill="url(#f)" d="M239.89,724.17a427.88,427.88,0,0,1,25.71-541l3.72,3.34A423.4,423.4,0,0,0,243.91,721.2Z"/>
                </g>
            </g>
        </svg>
        <img class="img-layer img-awards-part-3" src="images/services/services-partial-images/1/SVG/singer.svg" alt="singer">
        <paralax-container className="image-paralax-wrapper-4">
            <paralax-item :scrollY="true" :factor="0">
                <img class="img-layer img-awards-part-4" src="images/services/services-partial-images/1/SVG/vinil.svg" alt="vinil">
            </paralax-item>
        </paralax-container>
    </div>
</template>
<script>
import paralaxContainer from './../parallax-container';
import paralaxItem from './../parallax-item'

export default {
    data(){
        return {
            line1active: false,
            line2active: false,
            line3active: false,
            duration: 2000,
            loadDuration: 1000
        }
    },
    components: {
        "paralax-container": paralaxContainer,
        "paralax-item": paralaxItem
    },
    methods: {
        runLinesAnimation() {
            this.line1active = false;
            this.line2active = false;
            this.line3active = false;

            let delay = this.loadDuration / 3;

            setTimeout(() => {
                this.line1active = true;

                setTimeout(() => this.line1active = false, delay * 3);
            }, delay);
            
            setTimeout(() => {
                this.line2active = true;

                setTimeout(() => this.line2active = false, delay * 3);
            }, delay * 2);

            setTimeout(() => {
                this.line3active = true;

                setTimeout(() => this.line3active = false, delay * 3);
            }, delay * 3);
        }
    },
    mounted() {
        this.runLinesAnimation();
        setInterval(() => this.runLinesAnimation(), this.duration);
    }
}
</script>
<style lang="scss" scoped>
@import './../../scss/app';

.img-awards {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    $imgDesctHeight: 18vw;

    .img-layer {
        position: absolute;
        top: 0;
        display: block;
        width: 100%;
    }

    .img-awards-part-1 {
        top: -13.5vw;
        right: -7vw;
        width: 100%;
        height: $imgDesctHeight + 22vw;
        width: $imgDesctHeight + 23vw;
        z-index: 1;
    }

    .img-awards-part-2 {
        z-index: 2;
        top: -10vw;
        right: -2vw;
        height: $imgDesctHeight + 12vw;
        width: $imgDesctHeight + 15vw; 
        
        .lines {
            
            path {
                @include transition(opacity 1s);
                @include opacity(0);
            }

            path.active {
                @include transition(opacity 1s);
                @include opacity(1);
            }
        }
    }

    .img-awards-part-4 {
        position: relative;
        display: block;
        top: -5vw;
        height: $imgDesctHeight + 6vw;
        width: $imgDesctHeight + 6vw;
    }

    .image-paralax-wrapper-4 {
        z-index: 3;
    }
    
    .img-awards-part-3 {
        display: block;
        top:2.4vw;
        height: $imgDesctHeight;
        width: $imgDesctHeight;
        z-index: 4;
    }
}

@media (max-width: 992px) {
    .img-awards {       
        left: -3vw;
        $imgDesctHeight: 58vw;

        & &-part-1 {
            top: -32.5vw;
            right: -27vw;
            height: $imgDesctHeight + 52vw;
            width: $imgDesctHeight + 62vw;
        }

        & &-part-2 {
            top: -13vw;
            right: -9vw;
            height: $imgDesctHeight + 14vw;
            width: $imgDesctHeight + 24vw; 
        }

        & &-part-3 {
            top:2.4vw;
            height: $imgDesctHeight;
            width: $imgDesctHeight;
        }

        & &-part-4 {
            top: -11vw;
            height: $imgDesctHeight + 6vw;
            width: $imgDesctHeight + 6vw;
        }
    }  
}

@media (max-width: 576px) {
    .img-awards {       
        left: -3vw;
        $imgDesctHeight: 58vw;

        & &-part-1 {
            top: -32.5vw;
            right: -22vw;
            height: $imgDesctHeight + 52vw;
            width: $imgDesctHeight + 62vw;
        }

        & &-part-2 {
            top: -13vw;
            right: -4vw;
            height: $imgDesctHeight + 14vw;
            width: $imgDesctHeight + 24vw; 
        }

        & &-part-3 {
            top:5.4vw;
            height: $imgDesctHeight - 2vw;
            width: $imgDesctHeight  - 2vw;
        }

        & &-part-4 {
            top: -8vw;
            height: $imgDesctHeight + 6vw;
            width: $imgDesctHeight + 6vw;
        }
    }  
}

</style>