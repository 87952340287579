<template>
    <div class="img-branding">
        <img class="img-layer img-branding-part-1" src="images/services/services-partial-images/2/SVG/background.svg" alt="background">
        <svg class="img-layer img-branding-part-2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="2212.84" height="2103.94" viewBox="0 0 2212.84 2103.94">
            <defs>
                <linearGradient id="star-gradient" y1="1051.97" x2="2212.84" y2="1051.97" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#d64472"/>
                    <stop offset="1" stop-color="#efa137"/>
                </linearGradient>
            </defs>
            <polygon stroke-width="5px" stroke="url(#star-gradient)" fill="none" stroke-miterlimit="10" points="1106.66 6.28 1432.79 740.57 2206.66 805.48 1649.13 1282.43 2052.85 2098.61 1132.73 1663.59 279.95 2050.64 583.01 1274.09 6.66 805.48 783.5 769.74 1106.66 6.28"/>
        </svg>
        <paralax-container className="wrpper-img-branding-part-3">
            <paralax-item :scrollX="true" :scrollY="false" :factor="0">
                <img class="img-layer img-branding-part-3" src="images/services/services-partial-images/2/SVG/people.svg" alt="people">
            </paralax-item>
        </paralax-container>
        <paralax-container className="wrpper-img-branding-part-4">
            <paralax-item :scrollX="true" :scrollY="false" :factor="0">
                <img class="img-layer img-branding-part-4" src="images/services/services-partial-images/2/SVG/star.svg" alt="star">
            </paralax-item>
        </paralax-container>
    </div>
</template>
<script>
import paralaxContainer from './../parallax-container';
import paralaxItem from './../parallax-item'

export default {
    components: {
        "paralax-container": paralaxContainer,
        "paralax-item": paralaxItem
    }
}
</script>
<style lang="scss" scoped>
@import './../../scss/app';

.img-branding {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    $imgDesctHeight: 18vw;

    .img-layer {
        position: absolute;
        top: 0;
        display: block;
        width: 100%;
    }

    & &-part-1 {
        top: -6vw;
        width: 100%;
        height: $imgDesctHeight + 20vw;
        width: $imgDesctHeight + 20vw;
        z-index: 1;
    }

    & &-part-2 {
        z-index: 2;
        left: -3vw;
        top: -7vw;
        height: $imgDesctHeight + 16vw;
        width: $imgDesctHeight + 16vw;
        @include animation(star-strok 10s);
        @include animation-iteration-count(infinite);
        stroke-dasharray: 8500;
    }

    & &-part-4 {
        position: relative;
        top: 1.5vw;
        //left: -8vw;
        left: -10vw;
        height: $imgDesctHeight + 7vw;
        width: $imgDesctHeight + 7vw;
        z-index: 3;
    }
    
    & &-part-3 {
        position: relative;
        top: 10vw;
        //left: 12vw;
        left: 12.5vw;
        height: $imgDesctHeight - 9vw;
        width: $imgDesctHeight;
        z-index: 4;
    }
}

.wrpper-img-branding-part-4 {
    z-index: 3;
}

.wrpper-img-branding-part-3 {
    z-index: 4;
}

@include keyframes(star-strok) {
    0% {
        stroke-dashoffset: 8500px;
    }
    50% {
        stroke-dashoffset: 0px;
    }
    100% {
        stroke-dashoffset: -8500px;
    }
}

@media (max-width: 992px) {
    .img-branding {
        $imgDesctHeight: 58vw;

        & &-part-1 {
            top: -6vw;
            left: -4vw;
            width: 100%;
            height: $imgDesctHeight + 20vw;
            width: $imgDesctHeight + 20vw;
        }

        & &-part-2 {
            left: -3.5vw;
            top: -11vw;
            height: $imgDesctHeight + 16vw;
            width: $imgDesctHeight + 16vw;
        }

        & &-part-4 {
            top: 1.3vw;
            left: -1.3vw;
            height: $imgDesctHeight + 2vw;
            width: $imgDesctHeight + 2vw;
        }
        
        & &-part-3 {
            position: absolute;
            top: -4vw;
            left: 11.5vw;
            height: $imgDesctHeight - 9vw;
            width: $imgDesctHeight - 20vw;
        }
    }
}

@media (max-width: 576px) {
    .img-branding {
        $imgDesctHeight: 58vw;

        & &-part-1 {
            top: -6vw;
            left: 1vw;
            width: 100%;
            height: $imgDesctHeight + 20vw;
            width: $imgDesctHeight + 20vw;
        }

        & &-part-2 {
            left: 0.5vw;
            top: -11vw;
            height: $imgDesctHeight + 16vw;
            width: $imgDesctHeight + 16vw;
        }

        & &-part-3 {
            top: -4vw;
            left: 11.5vw;
            height: $imgDesctHeight - 9vw;
            width: $imgDesctHeight - 20vw;
        }

        & &-part-4 {
            top: 1.3vw;
            left: -1.3vw;
            height: $imgDesctHeight + 2vw;
            width: $imgDesctHeight + 2vw;
        }
    
    }
}

</style>